import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { pageMaxWidth } from '../styles/page-max-width'
import { CroppedText } from '../components/cropped-text'
import { SectionHead } from '../components/section-head'
import { Button } from '../components/button'
import { AssetImage } from '../components/image'
import { SvgRightAngle } from '../components/svg'
import { SectionOuter } from '../components/section-outer'
import { Animate } from '../components/animate'
import { useSettings } from '../hooks/use-settings'

const Outer = styled.div`
  background-color: var(--light-gray);
`

const Inner = styled.div`
  ${pageMaxWidth}
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }

`

const TextContainer = styled.div``

const Text1 = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  @media (max-width: 40rem){
    br {
      display: none;
    }
  }
`

const ButtonContainer = styled.div`
  margin-top: 2.5rem;

`

const List = styled.div`
  grid-column: span 2;
  grid-area: 4.375rem;
  display: grid;
  grid-template-columns: subgrid;
  row-gap: 4.375rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const Item = styled.a`
  grid-column: span 2;
  display: grid;
  grid-template-columns: subgrid;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const RightContainer = styled.div`
  padding-top: 2.5rem;
  margin-left: -1rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 40rem){
    margin-left: 0;
    padding-top: 2rem;
  }
`

const PostDate = styled(CroppedText)`
  font-size: 0.875rem;
`

const Text2 = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.8571428571428572;
  margin-top: 1.25rem;
`

const ViewMoreButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  gap: 1rem;
  align-items: center;
  @media (max-width: 40rem){
    margin-top: 1.5rem;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
    opacity: 0;
    transform: translateX(-1rem) translateY(0.125rem);
    .animate & {
      opacity: 1;
      transform: translateX(0) translateY(0.125rem);
      transition: opacity 0.4s 0.4s, transform 1s 0.4s;
    }

  }
  &::before {
    content: '';
    flex: 1 1 0;
    height: 1px;
    align-self: flex-end;
    background-color: #B3B3B3;
    transform: scaleX(0%);
    transform-origin: 0 0;
    .animate & {
      transition: transform 0.4s linear;
      transform: scaleX(100%);
    }
  }
  span {
    opacity: 0;
    .animate & {
      opacity: 1;
      transition: opacity 0.6s 0.4s;
    }
  }

`

const items = [
  {
    date: '2024.03.01',
    text: '女優 柴田理恵さんに、家じゅうどこでも置くだけで24時間365日ダニ対策ができる「ダニとり酵母」を試していただきました。実際に使用した際の使いやすさについて、お話を伺っています。',
    slug: '',
    img: 'topics_01.png'
  },
  {
    date: '2024.03.07',
    text: '子育て情報誌kodomoe（コドモエ）(2024年4月号/白泉社)の「"OYAKO LIFE" YELLOW-PAGE」でアプロスライフストアの商品「ダニとり酵母」が紹介されました。',
    slug: '',
    img: 'topics_02.png'
  },
]

type TopTopicsSectonProps = {

}
export const TopTopicsSecton: FunctionComponent<TopTopicsSectonProps> = () => {
  const {topics} = useSettings()
  return <Outer>
    <Inner>
      <SectionOuter>
        <SectionHead>TOPICS</SectionHead>
        <Content>
          <TextContainer>
            <Text1>
              APROSについて、製品に関する情報はこちらから。<br />
              私たちの想いやこだわり、そして、あなたの暮らしや<br />
              ライフスタイルに役立つ話題をまとめています。
            </Text1>
            <ButtonContainer>
              <Button href="/shop/information_categories/topics">
                View All
              </Button>
            </ButtonContainer>
          </TextContainer>
          <List>
            {
              topics?.map((topic, i) => {
                return <Item key={i} href={`/shop/information/${topic.slug}`}>
                  <ImageContainer>
                    <img src={topic.img} />
                  </ImageContainer>
                  <RightContainer>
                    <PostDate>
                      {topic.publishedAt}
                    </PostDate>
                    <Text2>
                      {topic.title}
                    </Text2>
                    <Animate>
                      <ViewMoreButton>
                        <span>View More</span>
                        <SvgRightAngle size={8} />
                      </ViewMoreButton>
                    </Animate>
                  </RightContainer>
                </Item>
              })
            }
          </List>
        </Content>
      </SectionOuter>
    </Inner>
    
  </Outer>
}