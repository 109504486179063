import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-head'
import { Button } from '../components/button'
import { CroppedText } from '../components/cropped-text'
import classNames from 'classnames'
import { SvgMinus, SvgPlus } from '../components/svg'
import { pageMaxWidth } from '../styles/page-max-width'
import { pageGrid } from '../styles/page-grid'
import { SectionOuter } from '../components/section-outer'

const Outer = styled.div`
  ${pageMaxWidth}
`

const Inner = styled.div`
  ${pageGrid}
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const LeftContainer = styled.div``

const Text = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  padding-right: 2rem;
  @media (max-width: 40rem){
    font-size: 0.75rem;
    line-height: 1.75;
  }
`

const ButtonContainer = styled.div`
  margin-top: 2.5rem;
`

const RightContainer = styled.div`
  grid-column: span 2;
`

const List = styled.div``

const items = [
  {
    q: '返品・交換はできますか',
    a: '申し訳ございませんが、お客様都合による返品・交換は承っておりません。\n（20日間返品保証は、この限りではありません。）\n詳しくはご利用ガイドの「返品について」をご確認ください。'
  },
  {
    q: 'ポスト投函便がポストに入りません',
    a: 'ポスト投函便が大きさ等の問題により投函不可の場合は、運送会社より「不在票」が投函されます。必ずご確認いただき、保管期間内に再配達のご依頼をお願いいたします。'
  },
  {
    q: '商品が届きません',
    a: '商品の配送状況につきましては、発送完了メールに記載している「追跡確認URL」よりご確認いただけます。商品発送日から7日以上経過しても商品が届かない場合は、住所不備や配送トラブルの可能性がございますので、配送会社までご連絡をお願いいたします。配送会社へ問い合わせても解決しない場合や、住所不備があった場合は、お問い合わせ窓口までご連絡ください。\n\n※住所不備の場合、住所変更のお手続きが必要となります。\n※商品未着のお問い合わせは、商品発送日から60日以内に限り対応させていただきます。\n\n＜お問い合わせ窓口＞\nTEL：0120-160-130　営業時間 9:00～18:00（土日祝日を除く）\nお問い合わせフォームはコチラ'
  },
]

type TopFaqSectionProps = {

}
export const TopFaqSection: FunctionComponent<TopFaqSectionProps> = () => {
  return <Outer>
    <SectionOuter>
      <SectionHead>
        FAQ
      </SectionHead>
      <Inner>
        <LeftContainer>
          <Text>
            当サイトに関する商品、お買い物についてのよくある質問をまとめています。初めて当サイトをご利用されるお客様は、ページ下部にある「ご利用ガイド」もご確認ください。
          </Text>
          <ButtonContainer>
            <Button href="/shop/faq">
              View All
            </Button>
          </ButtonContainer>
        </LeftContainer>
        <RightContainer>
          <List>
            {
              items.map((item, i) => {
                return <FaqItem key={i} data={item} />
              })
            }
          </List>
        </RightContainer>
      </Inner>
    </SectionOuter>
  </Outer>
}

const Item = styled.div`
  & + & {
    border-top: 1px solid #D4D4D4;
  }
`

const Row = styled.div`
  display: flex;
  gap: 2rem;
`

const Q = styled(Row)`
  padding: 2.5rem 1.875rem;
  cursor: pointer;
  white-space: pre-wrap;
  @media (max-width: 40rem){
    padding-left: 0;
    padding-right: 0;
  }
  ${Item}:first-child & {
    margin-top: -2.5rem;
  }
`
const AOuter = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 0.4s;
  ${Item}.hide & {
    grid-template-rows: 0fr;
  }
`
const A = styled(Row)`
  padding: 0 1.875rem 2.5rem;
  transition: opacity 0.4s, padding-bottom 0.4s;
  overflow: hidden;
  box-sizing: border-box;
  align-items: flex-start;
  @media (max-width: 40rem){
    padding-left: 0;
    padding-right: 0;
  }
  ${Item}.hide & {
    opacity: 0;
    padding-bottom: 0;
  }
`

const Icon = styled(CroppedText)`
  font-size: 1.375rem;
  letter-spacing: 0.1em;
  font-family: var(--serif-font);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10rem;
  border: 1px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  box-sizing: border-box;
  @media (max-width: 40rem){
    font-size: 1rem;
    letter-spacing: 0.1em;
    width: 1.875rem;
    height: 1.875rem;
  }
`

const QIcon = styled(Icon)`
  background-color: var(--text-color);
  color: white;
`

const Label = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`

const HideButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10rem;
  border: 1px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-left: auto;
  box-sizing: border-box;
  @media (max-width: 40rem){
    width: 1.875rem;
    height: 1.875rem;
  }
  svg {
    display: block;
    height: 0.875rem;
    width: auto;
  }
`

type FaqItemProps = {
  data: {
    q: string,
    a: string
  }
}

const FaqItem: FunctionComponent<FaqItemProps> = ({data}) => {
  const [hide, setHide] = useState(true)
  return <Item className={classNames({hide})}>
    <Q onClick={() => setHide(v => !v)}>
      <QIcon>
        Q
      </QIcon>
      <Label dangerouslySetInnerHTML={{__html: data.q}} />
      <HideButton>
        {
          hide ? <SvgPlus size={14} /> : <SvgMinus size={14} />
        }
      </HideButton>
    </Q>
    <AOuter>
      <A>
        <Icon>
          A
        </Icon>
        <Label dangerouslySetInnerHTML={{__html: data.a}} />
      </A>
    </AOuter>
  </Item>
}