import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgGraph01 from '../generated/svg/graph-01'
import SvgGraph02 from '../generated/svg/graph-02'
import { pageMaxWidth } from '../styles/page-max-width'
import { CroppedText } from '../components/cropped-text'
import { AssetImage } from '../components/image'
import { useBreakpoint } from '../hooks/use-break-point'
import SvgGraph02M from '../generated/svg/graph-02m'
import { Animate } from '../components/animate'
import { SectionOuter } from '../components/section-outer'
import { AnimateSvg } from '../components/animate-svg'
import { SectionHead } from '../components/section-head'

const Outer = styled.div``

const HeadContainer = styled.div`
  ${pageMaxWidth}
`

const Inner = styled.div`
  ${pageMaxWidth}
`


const Container = styled.div`
  margin-left: var(--page-margin);
  margin-right: var(--page-margin);
  display: grid;
  grid-template-columns: calc((100% - var(--page-margin) * 2) / 2) calc((100% - var(--page-margin) * 2) / 2 + var(--page-margin));
  column-gap: var(--page-margin);
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    margin-left: 0;
    margin-right: 0;
  }
  & + & {
    margin-top: 4.5rem;
  }  
`

const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
`

const Text1 = styled(CroppedText)`
  font-size: 2.25rem;
  line-height: 1.7777777777777777;
  font-family: var(--serif-font);
  @media (max-width: 40rem){
    font-size: 1.5rem;
line-height: 1.75;
  }
`

const Text2 = styled(CroppedText)`
  font-size: 0.9375rem;
  line-height: 1.8666666666666667;
  margin-top: 3.75rem;
  @media (max-width: 40rem){
    font-size: 0.8125rem;
line-height: 1.7692307692307692;
margin-top: 2.5rem;
  }
`

const List = styled.div`
  margin-top: 5rem;
  padding-left: 3.75rem;
  padding-top: 1.875rem;
  padding: 1.875rem;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: currentColor;
    transform: scaleY(0);
    transform-origin: 0 0;
    .animate & {
      transform: none;
      transition: transform 0.8s 0.2s;
    }
  }
`

const Text3 = styled(CroppedText)`
  font-size: 1.375rem;
  line-height: 2.1818181818181817;
  font-family: var(--serif-font);
  @media (max-width: 40rem){
    font-size: 1.125rem;
line-height: 1.4282626046074762;
  }
  &:not(:first-child) {
    margin-top: 3.125rem;
  }
`

const Text4 = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.8571428571428572;
  margin-top: 1.875rem;
  @media (max-width: 40rem){
    font-size: 0.75rem;
line-height: 1.9166666666666667;
margin-top: 1.25rem;
  }
`

const GraphContainer = styled.div`
  margin-top: auto;
  @media (max-width: 40rem){
    margin-top: 2rem;
  }
  svg {
    width: 100%;
    height: auto;
  }
`


const ImageContainer = styled.div`
  height: 100%;
  margin-left: var(--page-margin);
  margin-right: calc(var(--page-margin) * -2);
  width: 100%;
  display: flex;
  gap: 1.25rem;
  margin-top: 7.5rem;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`




type TopMethodSectionProps = {

}
export const TopMethodSection: FunctionComponent<TopMethodSectionProps> = () => {
  const isMobile = useBreakpoint('40rem')
  return <SectionOuter>
    <HeadContainer>
      <SectionHead>METHOD</SectionHead>
    </HeadContainer>
    <Inner>
      <Container>
        <ContainerInner>
          <GraphContainer>
            <AnimateSvg>
              <Animate>
                <SvgGraph01 />
              </Animate>
            </AnimateSvg>
          </GraphContainer>
        </ContainerInner>
        <ContainerInner>
          <Text1>
            暮らしに溶け込む<br />
          「快適さ」を求めて
          </Text1>
          <Text2>
            暮らしの「快適さ」は、心も体も気持ち良く感じるフィット感から生まれます。<br />
            日常生活の、ちょっとした不便さや小さな不満にも向き合い、改善していくことでフラットな充足感を。さらに、より手軽で便利な環境は充実した毎日につながります。そして、心も体も満たされれば快適な暮らしが実現できると、私たちは考えます。暮らしにフィットする「快適さ」を追求します。
          </Text2>
        </ContainerInner>
      </Container>
      <Container>
        <ContainerInner>
          <Text1>
            製品を「育む」という考え方
          </Text1>
          <Text2>
            私たちは、商品やサービスの開発において、何度もテストや実験をおこない、改善を繰り返していきます。開発中に見つけた発見や修正点はすべて反映していき、製品を育むような気持で作り上げていきます。もちろん製品化して終わりではありません。お客様の声にも耳を傾け、より良い製品づくりや品質向上に努め、育み続けます。
          </Text2>
          <Animate>
            <List>
              <Text3>
                自然と科学の融合
              </Text3>
              <Text4>
                伝統ある自然由来の素材にくわえ、先端の科学技術も活用し、
                最適な製品づくりを目指しています。
              </Text4>
              <Text3>
                貴重なお客様の声
              </Text3>
              <Text4>
                お客様から頂いたご意見・ご要望を真摯に受け止め、
                商品やサービスの向上に努めています。
              </Text4>
              <Text3>
                エビデンスや実験データ
              </Text3>
              <Text4>
                培ってきたノウハウや経験則にとらわれず、
                エビデンスや実験データも確認し機能を確認しています。
              </Text4>
            </List>
          </Animate>
        </ContainerInner>
        <ContainerInner>
          <GraphContainer>
            <AnimateSvg key={Number(isMobile)}>
              <Animate>
                {
                  isMobile ? <SvgGraph02M /> : <SvgGraph02 />
                }
              </Animate>
            </AnimateSvg>
          </GraphContainer>
        </ContainerInner>
      </Container>
      <ImageContainer>
        <AssetImage src="top_07.png"/>
      </ImageContainer>
    </Inner>
  </SectionOuter>
}