import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopBannerSection } from '../sections/top-banner-section'
import { TopConceptSection } from '../sections/top-concept-section'
import { TopTopicsSecton } from '../sections/top-topics-secton'
import { TopProductsSection } from '../sections/top-products-section'
import { TopMethodSection } from '../sections/top-method-section'
import { TopInformationSection } from '../sections/top-information-section'
import { TopFaqSection } from '../sections/top-faq-section'
import { AssetImage } from '../components/image'
import { useBreakpoint } from '../hooks/use-break-point'

const Outer = styled.div`
`

const ImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  const isMobile = useBreakpoint('40rem')
  return <Outer>
    <TopBannerSection />
    <TopConceptSection />
    <TopTopicsSecton />
    <ImageContainer>
      <AssetImage src={isMobile ? 'top_02m.jpg' : 'top_02.jpg'} />
    </ImageContainer>
    <TopProductsSection />
    <TopMethodSection />
    <TopInformationSection />
    <TopFaqSection />
  </Outer>
}