import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  display: contents;
  svg :is(path, circle) {
    transition: stroke-dashoffset;
    stroke-dasharray: var(--length);
    stroke-dashoffset: var(--length);
  }
  & > .animate svg :is(path, circle) {
    stroke-dashoffset: 0;
    transition-duration: 1s;
    transition-delay: 0.4s;
    transition-timing-function: ease-in-out;
  }
`

type AnimateSvgProps = {
  children?: ReactNode
}
export const AnimateSvg: FunctionComponent<AnimateSvgProps> = ({children}) => {
  const outerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const paths = outer.querySelectorAll<SVGPathElement>('path[stroke],circle[stroke]')
      paths.forEach(path => {
        path.style.setProperty('--length', path.getTotalLength().toString())
      })
    }
  })
  return <Outer ref={outerRef}>
    {children}
  </Outer>
}