import { useEffect, useState } from 'react'

export const useData = <T>(url: string) => {
  const [data, setData] = useState<T>()
  useEffect(() => {
    const res = fetch(url, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => setData(data))
      .catch(err => {})
  }, [])
  return data
}

export interface SetData {
  id: number;
  quantity: number;
  quantity_under_limit: number;
  quantity_upper_limit: number;
  product: Product;
  is_set_group: boolean;
  set_groups?: (SetGroupsEntity)[] | null;
  latest_set_groups_products?: null;
  products?: (ProductsEntity)[] | null;
  select?: (SelectEntity)[] | null;
}
interface Product {
  id: number;
  number: string;
  pickup_allowed: boolean;
  state: string;
  name: string;
  for_sale: boolean;
  upsell_product_id?: null;
  cv_upsell_product_id?: null;
  co_selling_product_id?: null;
  maker_id?: null;
  tax_id: number;
  is_reduced_tax: boolean;
  position: number;
  note?: null;
  description: string;
  description_mobile: string;
  sub_description: string;
  sub_description_mobile: string;
  meta_description: string;
  meta_keywords: string;
  additional_header_content: string;
  created_at: string;
  updated_at: string;
  deleted_at?: null;
  released_at?: null;
  publish_start_at?: null;
  publish_end_at?: null;
  is_recurring: boolean;
  remaining_number_of_orders?: null;
  recurring_block_times: number;
  caution: string;
  caution02: string;
  customer_rank_threshold_id?: null;
  point_reward_rate: number;
  delivery_fee_template_id?: null;
  payment_method_fee_template_id?: null;
  payment_schedule?: null;
  scheduled_to_be_delivered_every_x_month?: null;
  scheduled_to_be_delivered_on_xth_day: number;
  scheduled_to_be_delivered_every_x_day?: null;
  scheduled_to_be_delivered_on_xth_day_of_week?: null;
  scheduled_to_be_delivered_every_x_day_of_week?: null;
  forbidden_product_at_the_same_time: string;
  option01: string;
  option02: string;
  option03: string;
  option04: string;
  option05: string;
  option06: string;
  option07: string;
  option08: string;
  option09: string;
  option10: string;
  age_check_required: boolean;
  purchase_customers_restriction: boolean;
  min_age_limit: number;
  export_feed: boolean;
  feed_public_url: string;
  product_auth_name: string;
  product_auth_password: string;
  jaccs_webby_code?: null;
  link_number: string;
  purchase_restrictions_settings_for_bundled_item: boolean;
  exclude_from_sale_ranking: boolean;
  order_cancelable_on_mypage: boolean;
}
interface SetGroupsEntity {
  id: number;
  plan_id: number;
  group_name: string;
  quantity: number;
  created_at: string;
  updated_at: string;
  deleted_at?: null;
}
interface ProductsEntity {
  id: number;
  name: string;
  master: Master;
  thumbnail: string;
  tax_value: number;
  option_types?: (null)[] | null;
  variants?: (VariantsEntity)[] | null;
}
interface Master {
  id: number;
  state: string;
  sku: string;
  is_master: boolean;
  for_sale: boolean;
  product_id: number;
  description?: null;
  description_mobile?: null;
  position: number;
  cost: number;
  volume: number;
  list_price: number;
  sales_price: number;
  limit_quantity: boolean;
  min_quantity: number;
  max_quantity: number;
  customer_limit_quantity?: null;
  is_new: boolean;
  is_sale: boolean;
  products_addable_on_mypage: boolean;
  visible_in_cart: boolean;
  visible_in_email: boolean;
  visible_in_report: boolean;
  point_exchangeable: boolean;
  link_number?: null;
  deleted_at?: null;
  created_at: string;
  updated_at: string;
}
interface VariantsEntity {
  id: number;
  number: string;
  name: string;
  sku: string;
  list_price: string;
  sales_price: string;
  list_price_include_tax: number;
  sales_price_include_tax: number;
  limit_quantity: number;
  min_quantity: number;
  max_quantity: number;
  description: string;
  option_values?: (null)[] | null;
  thumbnails?: (string)[] | null;
  out_of_stock: boolean;
}
interface SelectEntity {
  id: number;
  quantity: number;
  sales_price: string;
  tax_value: number;
}

export const useSetData = (url?: string) => {
  return useData<SetData>(url ?? location.href)  
}