import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-head'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSwiperClass } from '../hooks/useSwiperClass'
import { CroppedText } from '../components/cropped-text'
import { SvgLeftAngle, SvgRightAngle } from '../components/svg'
import { Button } from '../components/button'
import { pageMaxWidth } from '../styles/page-max-width'
import { slideWidth } from '../styles/slide-width'
import classNames from 'classnames'
import { SectionOuter } from '../components/section-outer'
import { useSettings } from '../hooks/use-settings'

const Outer = styled.div`
  background-color: #F2F2F2;
`

const Inner = styled.div`
  ${pageMaxWidth}
`

const SlideContainer = styled.div`
  --slides-per-view: 3;
  --gap: 2rem;
  @media (max-width: 40rem){
    --slides-per-view: 1;
  }
  .swiper {
    overflow: visible;
  }
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    ${slideWidth}
    height: auto;
  }
`

const Item = styled.a`
  padding: 1.875rem;
  border: 1px solid currentColor;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
`

const PostDate = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 2.2857142857142856;
`

const Title = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  margin-top: 1.25rem;
`

const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: 1.5rem;

`

const ViewMore = styled.div`
  align-items: center;
  display: flex;
  font-family: var(--serif-font);
  gap: 1rem;
  align-self: flex-end;
  padding-top: 2rem;
  margin-top: auto;
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
  }
`

const ViewMoreLabel = styled(CroppedText)`
  font-size: 0.9375rem;
  line-height: 25.333333333333332;
  letter-spacing: 0.1em;
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
`

const ButtonContainer = styled.div`
`

const Controls = styled.div`
  display: flex;
  gap: 2rem;
`

const ControlsItem = styled.div`
  margin: -1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  svg {
    display: block;
    height: 0.75rem;
    width: auto;
  }
  
`

const items = [
  {
    publishedAt: '2023.01.00',
    title: '年末年始休業のお知らせ',
    text: '平素はアプロスに格別のご愛顧を賜りまして心より感謝いたします。12月29日(金)～1月4日(木)の期間、商品テキストテキストテキストテキストテキストテキスト',
    slug: ''
  },
  {
    publishedAt: '2023.01.00',
    title: 'マイページ機能の再開について',
    text: '平素はアプロスに格別のご愛顧を賜りまして心より感謝いたします。停止しておりましたマイページの機能をテキストテキストテキストテキスト',
    slug: ''
  },
  {
    publishedAt: '2023.01.00',
    title: '佐川急便システムトラブルによる配送への影響について',
    text: '平素はアプロスに格別のご愛顧を賜りまして心より感謝いたします。8月24日 佐川急便のシステムトラブルがテキストテキストテキストテキスト',
    slug: ''
  }
]

type TopInformationSectionProps = {

}
export const TopInformationSection: FunctionComponent<TopInformationSectionProps> = () => {
  const {ref, slideNext, slidePrev, activeIndex, slideCount} = useSwiperClass()
  const {news} = useSettings()
  return <Outer>
    <SectionOuter>
      <Inner>
        <SectionHead>
          INFORMATION
        </SectionHead>
        <SlideContainer>
          <Swiper
            onSwiper={ref}
            slidesPerView="auto"
          >
            {
              news.map((item, i) => {
                return <SwiperSlide key={i}>
                  <Item href={`/shop/information/${item.slug}`}>
                    <PostDate>
                      {item.publishedAt}
                    </PostDate>
                    <Title>
                      {item.title}
                    </Title>
                    <Text>
                      {item.content}
                    </Text>
                    <ViewMore>
                      <ViewMoreLabel>View More</ViewMoreLabel>
                      <SvgRightAngle size={8} />
                    </ViewMore>
                  </Item>
                </SwiperSlide>
              })
            }
          </Swiper>
        </SlideContainer>
        <BottomRow>
          <ButtonContainer>
            <Button href="/shop/information_categories/news">View All</Button>
          </ButtonContainer>
          <Controls>
            <ControlsItem className={classNames({disabled: activeIndex < 1})} onClick={() => slidePrev()}>
              <SvgLeftAngle size={12} />
            </ControlsItem>
            <ControlsItem className={classNames({disabled: activeIndex > slideCount - 2})} onClick={() => slideNext()}>
              <SvgRightAngle size={12} />
            </ControlsItem>
          </Controls>
        </BottomRow>
      </Inner>
    </SectionOuter>
  </Outer>
}