import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionOuter } from '../components/section-outer'
import { CroppedText } from '../components/cropped-text'
import { pageMaxWidth } from '../styles/page-max-width'
import { SectionHead } from '../components/section-head'

const Outer = styled.div`
  ${pageMaxWidth}
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 1.5rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 2.5rem; 
  }
`

const Text1 = styled(CroppedText)`
  font-size: 2.25rem;
  line-height: 1.7777777777777777;
  font-family: var(--serif-font);
  @media (max-width: 40rem){
    font-size: 1.5rem;
    line-height: 1.75;
  }
`

const TextContainer = styled.div`
  display: grid;
  grid-column: span 2;
  grid-template-columns: subgrid;
  @media (max-width: 60rem){
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`

const Text2 = styled(CroppedText)`
  font-size: 0.9375rem;
  line-height: 1.8666666666666667;
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    line-height: 1.7692307692307692;
  }
`


type TopConceptSectionProps = {

}
export const TopConceptSection: FunctionComponent<TopConceptSectionProps> = () => {
  return <Outer>
    <SectionOuter>
      <SectionHead>
        CONCEPT
      </SectionHead>
      <Inner>
        <Text1>
          暮らしに自分らしさを。<br />
          今とこれからを愉しむ。
        </Text1>
        <TextContainer>
          <Text2>
            「暮らし」とは住むことではなく、生きることです。生活という言葉とは違い、暮らしにはライフスタイルや生き方も含まれます。だからこそ「自分らしさ」が大切です。好きなものに囲まれて、もっと私を好きになる。理想を叶える生き方のために、暮らしからサポートします。
          </Text2>
          <Text2>
            今をもっと便利に。そして、これからも明るく快適な環境に。選ぶことから始まる「愉しさ」こそ、自分らしさが詰まった感情です。彩り豊かな日々の暮らしが、鮮やかな未来を創りだす。その想いから生まれたカラフルなアイデアを、カタチにしていきます。
          </Text2>
        </TextContainer>
      </Inner>
    </SectionOuter>
  </Outer>
}