import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8.75rem;
  padding-bottom: 8.75rem;
  @media (max-width: 40rem){
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`

type SectionOuterProps = {
  children?: ReactNode
}
export const SectionOuter: FunctionComponent<SectionOuterProps> = ({children}) => {
  return <Outer>
    {children}
  </Outer>
}